/* eslint-disable no-unused-vars */
import React from 'react';
import { Container, Text, createStyles } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import ExperienceProfessional from './Experience/Professional';
import ExperienceCurricular from './Experience/Curricular';

const useStyles = createStyles((theme) => ({
  root: {
  },
}));

export default function Experience() {
  const { t, i18n } = useTranslation();
  const { classes, cx } = useStyles();

  return (
    <Container>
      <ExperienceProfessional />
      <ExperienceCurricular />
    </Container>
  );
}

/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  createStyles, Header, Container, Group, Burger, Paper, Transition, Button, Title, MediaQuery,
  useMantineTheme,
} from '@mantine/core';
import { Link } from 'react-router-dom';
import { useBooleanToggle } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Logo } from '../logoJT.svg';

const HEADER_HEIGHT = 60;

const useStyles = createStyles((theme) => ({
  root: {
    position: 'sticky',
    zIndex: 120,
  },

  dropdown: {
    position: 'absolute',
    top: HEADER_HEIGHT,
    left: 0,
    right: 0,
    zIndex: 0,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopWidth: 0,
    overflow: 'hidden',
    height: 'fit-content',

    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
    maxWidth: '100%',
  },

  languages: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  burger: {
    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },

  language: {
    display: 'block',
    lineHeight: 1,
    padding: '8px 12px',
    borderRadius: theme.radius.sm,
    textDecoration: 'none',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },

    [theme.fn.smallerThan('sm')]: {
      borderRadius: 0,
      width: '100%',
      // padding: theme.spacing.md,
    },
  },

  languageActive: {
    '&, &:hover': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.fn.rgba(theme.colors[theme.primaryColor][9], 0.25)
          : theme.colors[theme.primaryColor][0],
      color: theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 3 : 7],
    },
  },

  logo: {
    height: '40px',
    width: '40px',
    boxShadow: '5px 5px 0 goldenrod',
    borderRadius: '10px',
    '&:hover': {
      boxShadow: '5px 5px 0 grey',
    },
    marginRight: '10px',
    [theme.fn.largerThan('sm')]: {
      marginInline: '100px',
    },
  },
}));

interface HeaderResponsiveProps {
  languages: { name: string; code: string }[];
  navbarOpened: boolean;
  toggleNavbarOpened: Function;
}

export default function HeaderResponsive(
  { languages, navbarOpened, toggleNavbarOpened }: HeaderResponsiveProps,
) {
  const theme = useMantineTheme();
  const [opened, toggleOpened] = useBooleanToggle(false);
  const [active, setActive] = useState(languages[0]?.name);
  const { classes, cx } = useStyles();
  const { t, i18n } = useTranslation();

  const items = languages.map((language) => (
    <Button
      key={language.code}
      type="button"
      className={cx(classes.language, { [classes.languageActive]: active === language.name })}
      onClick={(event: { preventDefault: () => void; }) => {
        event.preventDefault();
        setActive(language.name);
        toggleOpened(false);
        i18n.changeLanguage(language.code);
      }}
    >
      {language.name}
    </Button>
  ));

  return (
    <Header height={HEADER_HEIGHT} className={classes.root}>
      <Container className={classes.header}>
        <MediaQuery largerThan="md" styles={{ display: 'none' }}>
          <Burger
            opened={navbarOpened}
            onClick={() => toggleNavbarOpened((o: boolean) => !o)}
            className={classes.burger}
            size="sm"
            color={theme.colors.gray[6]}
            mr="xl"
          />
        </MediaQuery>
        <Link to="/" className="no-link-decoration">
          <Logo className={classes.logo} />
        </Link>
        <Title order={3}>{t('title')}</Title>
        <Group spacing={5} className={classes.languages}>
          {items}
        </Group>

        <MediaQuery largerThan="md" styles={{ display: 'none' }}>
          <Burger
            opened={opened}
            onClick={() => toggleOpened()}
            className={classes.burger}
            size="sm"
            color={theme.colors.gray[6]}
            ml="xl"
          />
        </MediaQuery>

        <Transition transition="slide-down" duration={200} mounted={opened}>
          {(styles) => (
            <Paper className={classes.dropdown} withBorder style={styles}>
              {items}
            </Paper>
          )}
        </Transition>
      </Container>
    </Header>
  );
}

import React from 'react';
import {
  BackgroundImage, Text, Box, Collapse, createStyles, Grid,
} from '@mantine/core';
import { IconChevronDown, IconChevronUp } from '@tabler/icons';
import { useLocation } from 'react-router-dom';

const useStyles = createStyles((theme) => ({
  generalBox: {
    marginBlock: theme.spacing.md,
  },
  box: {
    margin: 0,
    cursor: 'pointer',
    '&:hover': { filter: 'brightness(90%)' },
  },
  backgroundImage: {
    [theme.fn.smallerThan('sm')]: {
      borderRadius: 0,
      width: '100%',
    },
    backgroundColor: 'var(--accent-color)',
  },
  text: {
    padding: theme.spacing.xl,
    color: '#ffffff',
    textAlign: 'center',
    fontSize: 30,
  },
  arrow: {
    paddingTop: theme.spacing.xl,
    color: '#ffffff',
    textAlign: 'center',
    [theme.fn.smallerThan('sm')]: {
      textAlign: 'left',
      paddingLeft: 0,
    },
  },
  content: {
    margin: theme.spacing.md,
  },
}));

interface CustomAccordionProps {
    hash: string;
    title: string;
    content: React.ReactNode;
}

export default function CustomAccordion({ hash, title, content }: CustomAccordionProps) {
  const [open, setOpen] = React.useState(false);
  const { classes } = useStyles();
  const location = useLocation();

  React.useEffect(() => {
    setOpen(location?.hash === `#${hash}`);
  }, [location]);

  return (
    <Box className={classes.generalBox}>
      <Box onClick={() => setOpen((o) => !o)} className={classes.box}>
        <BackgroundImage
          src=""
          // src="https://images.unsplash.com/photo-1419242902214-272b3f66ee7a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=720&q=80"
          radius="md"
          className={classes.backgroundImage}
        >
          <Grid>
            <Grid.Col span={1} />
            <Grid.Col span={10}>
              <Text weight={700} inline className={classes.text}>
                {title}
              </Text>
            </Grid.Col>
            <Grid.Col span={1} className={classes.arrow}>
              {open ? <IconChevronUp />
                : <IconChevronDown />}
            </Grid.Col>
          </Grid>
        </BackgroundImage>
      </Box>
      <Collapse in={open} transitionDuration={500} transitionTimingFunction="ease" id="collapse">
        <Box className={classes.content} id="accordion-box">
          {content}
        </Box>
      </Collapse>
    </Box>
  );
}

/* eslint-disable no-empty-pattern */
import React from 'react';
import { Box, createStyles, Title } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import ExperienceProfessionalEntry from './Professional/Entry';
import ExperienceProfessionalWorldline from './Professional/Contents/Worldline';
import ExperienceProfessionalSabadell from './Professional/Contents/Sabadell';
import VNLogo from '../../assets/experience/Logo-Viewnext.png';
import WlLogo from '../../assets/experience/wl-logo.png';
import BSLogo from '../../assets/experience/banc-sabadell-logo.png';
import ExperienceProfessionalViewNext from './Professional/Contents/ViewNext';

const useStyles = createStyles((theme) => ({
  generalBox: {
    marginBlock: theme.spacing.sm,
    padding: theme.spacing.sm,
  },
  detailsBox: {
    margin: theme.spacing.sm,
  },
}));

interface ExperienceProfessionalProps {
}

export default function ExperienceProfessional({}: ExperienceProfessionalProps) {
  const { classes } = useStyles();
  const { t } = useTranslation('translation', { keyPrefix: 'experience.content.professional' });

  return (
    <Box className={classes.generalBox}>
      <Title order={1}>{t('title')}</Title>
      <ExperienceProfessionalEntry
        title={t('content.viewnext.title')}
        description={t('content.viewnext.description')}
        startDate={t('content.viewnext.startDate')}
        endDate={t('content.viewnext.endDate')}
        logo={VNLogo}
      >
        <ExperienceProfessionalViewNext className={classes.detailsBox} />
      </ExperienceProfessionalEntry>
      <ExperienceProfessionalEntry
        title={t('content.worldline.title')}
        description={t('content.worldline.description')}
        startDate={t('content.worldline.startDate')}
        endDate={t('content.worldline.endDate')}
        logo={WlLogo}
      >
        <ExperienceProfessionalWorldline className={classes.detailsBox} />
      </ExperienceProfessionalEntry>
      <ExperienceProfessionalEntry
        title={t('content.sabadell-teller.title')}
        description={t('content.sabadell-teller.description')}
        startDate={t('content.sabadell-teller.startDate')}
        endDate={t('content.sabadell-teller.endDate')}
        logo={BSLogo}
      >
        <ExperienceProfessionalSabadell className={classes.detailsBox} />
      </ExperienceProfessionalEntry>
    </Box>
  );
}

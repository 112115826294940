/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Group, Box, Collapse, ThemeIcon, Text, UnstyledButton, createStyles,
} from '@mantine/core';
import {
  TablerIcon, IconChevronLeft, IconChevronRight,
} from '@tabler/icons';

const useStyles = createStyles((theme) => ({
  control: {
    fontWeight: 500,
    display: 'block',
    width: '100%',
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
    fontSize: theme.fontSizes.sm,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    },
  },

  noLinkDecoration: {
    textDecoration: 'none',
  },

  link: {
    fontWeight: 500,
    display: 'block',
    textDecoration: 'none',
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
    paddingLeft: 31,
    marginLeft: 30,
    fontSize: theme.fontSizes.sm,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
    borderLeft: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    },
  },

  chevron: {
    transition: 'transform 200ms ease',
  },
}));

interface LinksGroupProps {
  icon: TablerIcon;
  label: string;
  links?: { label: string; link: string, hash?: string }[];
  mainRoute?: string;
  toggleNavbarOpened?: Function;
}

export function LinksItem({
  icon: Icon, label, links, mainRoute, toggleNavbarOpened,
}: LinksGroupProps) {
  const { classes, theme } = useStyles();
  const hasLinks = Array.isArray(links);
  const [opened, setOpened] = useState(true);
  const location = useLocation();

  const ChevronIcon = theme.dir === 'ltr' ? IconChevronRight : IconChevronLeft;
  const items = (hasLinks && location.pathname === mainRoute ? links : []).map((link) => (
    <Text<'a'>
      component="a"
      className={classes.link}
      href={`${link.link}#${link.hash}`}
      key={link.label}
      onClick={(e) => {
        if (!link.hash) e.preventDefault();
        if (toggleNavbarOpened) toggleNavbarOpened((o: boolean) => !o);
      }}
    >
      {link.label}
    </Text>
  ));

  const setOpenedState = () => setOpened((o) => (location.pathname === mainRoute ? !o : o));

  return (
    <>
      <UnstyledButton onClick={() => setOpenedState()} className={classes.control}>
        <Group position="apart" spacing={0}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ThemeIcon variant="light" size={30}>
              <Icon size={18} />
            </ThemeIcon>
            <Box ml="md">{label}</Box>
          </Box>
          {hasLinks && location.pathname === mainRoute && (
            <ChevronIcon
              className={classes.chevron}
              size={14}
              stroke={1.5}
              style={{
                transform: opened ? `rotate(${theme.dir === 'rtl' ? -90 : 90}deg)` : 'none',
              }}
            />
          )}
        </Group>
      </UnstyledButton>
      {hasLinks && location.pathname === mainRoute
        ? <Collapse in={opened}>{items}</Collapse> : null}
    </>
  );
}

export default function LinksGroup(props: LinksGroupProps) {
  const location = useLocation();

  const { classes } = useStyles();
  const isLink = () => (props.mainRoute && location.pathname !== props.mainRoute);

  return isLink()
    ? (
      <Link
        to={props.mainRoute!}
        className={classes.noLinkDecoration}
        target={props.mainRoute?.includes('http') ? '_blank' : ''}
        onClick={() => {
          if (props.toggleNavbarOpened) props.toggleNavbarOpened((o: boolean) => !o);
        }}
      >
        <LinksItem {...props} />
      </Link>
    )
    : <LinksItem {...props} />;
}

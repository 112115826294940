/* eslint-disable no-empty-pattern */
import React from 'react';
import { Box, createStyles, Title } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import ExperienceCurricularEntry from './Curricular/Entry';
// import WlLogo from '../../assets/wl-logo.png';
// import BSLogo from '../../assets/banc-sabadell-logo.png';

const useStyles = createStyles((theme) => ({
  generalBox: {
    marginBlock: theme.spacing.sm,
    padding: theme.spacing.sm,
  },
  detailsBox: {
    margin: theme.spacing.sm,
  },
}));

interface ExperienceCurricularProps {
}

export default function ExperienceCurricular({}: ExperienceCurricularProps) {
  const { classes } = useStyles();
  const { t } = useTranslation('translation', { keyPrefix: 'experience.content.curricular' });

  return (
    <Box className={classes.generalBox}>
      <Title order={1}>{t('title')}</Title>
      <ExperienceCurricularEntry
        title={t('content.teaching.title')}
        content={[t('content.teaching.content.master'),
          t('content.teaching.content.degree')]}
      />
      <ExperienceCurricularEntry
        title={t('content.representative.title')}
        content={[t('content.representative.content.masters'),
          t('content.representative.content.graduation'),
          t('content.representative.content.degree')]}
      />
    </Box>
  );
}

/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-undef */
/* eslint-disable no-empty-pattern */
import React from 'react';
import {
  Box, createStyles, Text, Title, Grid, Image,
} from '@mantine/core';

const useStyles = createStyles((theme) => ({
  generalBox: {
    paddingBlock: theme.spacing.sm,
  },
  box: {
    justifyContent: 'center',
  },
  title: {
  },
  date: {
    color: 'var(--accent-color-dark)',
  },
  description: {
    paddingBottom: theme.spacing.sm,
  },
  children: {
    marginBlock: theme.spacing.md,
  },
  logo: {
    alignSelf: 'center',
    maxWidth: '200px',
  },
}));

interface ExperienceProfessionalProps {
  title: string,
  description: string,
  startDate: string,
  endDate: string,
  // eslint-disable-next-line react/require-default-props
  logo?: any,
  children?: JSX.Element,
}

export default function ExperienceCurricularEntry({
  title, description, startDate, endDate, logo, children = <></>,
}: ExperienceProfessionalProps) {
  const { classes } = useStyles();

  return (
    <Box className={classes.generalBox}>
      <Grid className={classes.box}>
        <Grid.Col xs={2} className={classes.logo}>
          <Image src={logo} fit="contain" />
        </Grid.Col>
        <Grid.Col xs={10}>
          <Title className={classes.title} order={2}>{title}</Title>
          <Text className={classes.date} transform="uppercase" weight={700}>
            {startDate}
            {' - '}
            {endDate}
          </Text>
          <Text className={classes.description} size="md">{description}</Text>
        </Grid.Col>
        <Grid.Col xs={12}>
          <Box className={classes.children}>{children}</Box>
        </Grid.Col>
      </Grid>
    </Box>
  );
}

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty-pattern */
import React from 'react';
import {
  Box, createStyles, Text, Title, Grid, Collapse,
} from '@mantine/core';
import { useTranslation } from 'react-i18next';
import {
  IconServer2, IconDeviceLaptop, IconDevices, IconSettings, IconBrandAmazon,
  IconChevronDown, IconChevronUp,
} from '@tabler/icons';

const useStyles = createStyles((theme) => ({
  generalBox: {
    marginBlock: theme.spacing.sm,
  },
}));

interface ExperienceProfessionalViewNextProps {
  [x: string]: any,
}

export default function ExperienceProfessionalViewNext(
  { ...props }: ExperienceProfessionalViewNextProps,
) {
  const { classes, theme } = useStyles();
  const { t } = useTranslation('translation', { keyPrefix: 'experience.content.professional' });
  const [opened, setOpened] = React.useState(false);

  return (
    <Box {...props}>
      <Title order={4} align="center" onClick={() => setOpened(!opened)} sx={{ padding: 0, marginBottom: theme.spacing.sm }}>
        {t('content.viewnext.contents.title')}
        {opened ? (
          <IconChevronUp style={{ width: 15, height: 15, marginInline: 5 }} />)
          : <IconChevronDown style={{ width: 15, height: 15, marginInline: 5 }} />}
      </Title>
      <Collapse in={opened} transitionDuration={500} transitionTimingFunction="ease">
        <Grid justify="space-between" className="border-gradient" grow>
          <Grid.Col xs={6}>
            <Title order={5} align="left">
              {t('content.viewnext.contents.frontend.title')}
              {' '}
              <IconDeviceLaptop />
            </Title>
            <Text size="sm">{t('content.viewnext.contents.frontend.angular')}</Text>
          </Grid.Col>
          <Grid.Col xs={6}>
            <Title order={5} align="left">
              {t('content.viewnext.contents.other.title')}
              {' '}
              <IconSettings />
            </Title>
            <Text size="sm">{t('content.viewnext.contents.other.git')}</Text>
            <Text size="sm">{t('content.viewnext.contents.other.apirest')}</Text>
            <Text size="sm">{t('content.viewnext.contents.other.scrum')}</Text>
          </Grid.Col>
        </Grid>
      </Collapse>
    </Box>
  );
}

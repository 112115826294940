/* eslint-disable no-empty-pattern */
import React from 'react';
import { Box, createStyles } from '@mantine/core';
import { useTranslation } from 'react-i18next';
// import WlLogo from '../../assets/wl-logo.png';
import CoursesEntry from './Entry';

const useStyles = createStyles((theme) => ({
  generalBox: {
    marginBlock: theme.spacing.sm,
    padding: theme.spacing.sm,
  },
}));

interface CoursesProps {
}

export default function Courses({}: CoursesProps) {
  const { classes } = useStyles();
  const { t } = useTranslation('translation', { keyPrefix: 'courses.content' });

  return (
    <Box className={classes.generalBox} id="courses-general-box">
      <CoursesEntry
        title={t('ele.title')}
        level={t('ele.level')}
        startDate={t('ele.startDate')}
        endDate={t('ele.endDate')}
        place={t('ele.place')}
        hours={t('ele.hours')}
      />
    </Box>
  );
}

/* eslint-disable no-unused-vars */
import React from 'react';
import { Container, ActionIcon, createStyles } from '@mantine/core';
import { IconChevronsUp } from '@tabler/icons';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import ProfileBanner from './components/ProfileBanner';
import CustomAccordion from './components/CustomAccordion';
import Experience from './components/Experience';
import Languages from './components/Languages/Languages';
import Abilities from './components/Abilities/Abilities';
import Courses from './components/Courses/Courses';
import Education from './components/Education/Education';
import Aptitudes from './components/Aptitudes/Aptitudes';
import { LANGUAGES } from './config/constants';

const useStyles = createStyles((theme) => ({
  root: {
  },
  container: {
    [theme.fn.smallerThan('sm')]: {
      padding: 0,
    },
  },
  fab: {
    position: 'fixed',
    bottom: '80px',
    right: '50px; ',
    cursor: 'pointer',
    zIndex: 100,
    // transition: 'all .3s ease-in-out',
  },
}));

export default function Content() {
  const { t, i18n } = useTranslation();
  const { classes, cx } = useStyles();
  const [fabHidden, toggleFabHidden] = React.useState(true);
  const { lang } = useParams();
  const navigate = useNavigate();

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  const onScroll = (e: any) => {
    toggleFabHidden(e.target.documentElement.scrollTop < 100);
  };

  React.useEffect(() => {
    window.addEventListener('scroll', onScroll);
    if (lang && LANGUAGES.find((l) => l.code === lang)) {
      changeLanguage(lang);
      navigate('/');
    }
  }, []);

  return (
    <Container className={classes.container}>
      <ProfileBanner />
      <Aptitudes />
      <div id="experience"><CustomAccordion hash="experience" title={t('experience.title')} content={<Experience />} /></div>
      <div id="languages"><CustomAccordion hash="languages" title={t('languages.title')} content={<Languages />} /></div>
      <div id="education"><CustomAccordion hash="education" title={t('education.title')} content={<Education />} /></div>
      <div id="courses"><CustomAccordion hash="courses" title={t('courses.title')} content={<Courses />} /></div>
      <div id="abilities"><CustomAccordion hash="abilities" title={t('abilities.title')} content={<Abilities />} /></div>
      <a href="/#top" className={classes.fab} hidden={fabHidden}>
        <ActionIcon color="primary" size="xl" radius="md" variant="filled">
          <IconChevronsUp size="24" />
        </ActionIcon>
      </a>
    </Container>
  );
}

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty-pattern */
import React from 'react';
import {
  Box, createStyles, Text, Title, Grid, Collapse,
} from '@mantine/core';
import { useTranslation } from 'react-i18next';
import {
  IconServer2, IconDeviceLaptop, IconDevices, IconSettings, IconBrandAmazon,
  IconChevronDown, IconChevronUp,
} from '@tabler/icons';

const useStyles = createStyles((theme) => ({
  generalBox: {
    marginBlock: theme.spacing.sm,
  },
}));

interface ExperienceProfessionalWorldlineProps {
  [x: string]: any,
}

export default function ExperienceProfessionalWorldline(
  { ...props }: ExperienceProfessionalWorldlineProps,
) {
  const { classes, theme } = useStyles();
  const { t } = useTranslation('translation', { keyPrefix: 'experience.content.professional' });
  const [opened, setOpened] = React.useState(false);

  return (
    <Box {...props}>
      <Title order={4} align="center" onClick={() => setOpened(!opened)} sx={{ padding: 0, marginBottom: theme.spacing.sm }}>
        {t('content.worldline.contents.title')}
        {opened ? (
          <IconChevronUp style={{ width: 15, height: 15, marginInline: 5 }} />)
          : <IconChevronDown style={{ width: 15, height: 15, marginInline: 5 }} />}
      </Title>
      <Collapse in={opened} transitionDuration={500} transitionTimingFunction="ease">
        <Grid justify="space-between" className="border-gradient" grow>
          <Grid.Col xs={6} md={4} xl={2}>
            <Title order={5} align="left">
              {t('content.worldline.contents.backend.title')}
              {' '}
              <IconServer2 />
            </Title>
            <Text size="sm">{t('content.worldline.contents.backend.java')}</Text>
            <Text size="sm">{t('content.worldline.contents.backend.node')}</Text>
            <Text size="sm">{t('content.worldline.contents.backend.spring')}</Text>
            <Text size="sm">{t('content.worldline.contents.backend.graphql')}</Text>
            <Text>{t('content.worldline.contents.backend.nest')}</Text>
          </Grid.Col>
          <Grid.Col xs={6} md={4} xl={2}>
            <Title order={5} align="left">
              {t('content.worldline.contents.aws.title')}
              {' '}
              <IconBrandAmazon />
            </Title>
            <Text size="sm">{t('content.worldline.contents.aws.appsync')}</Text>
            <Text size="sm">{t('content.worldline.contents.aws.s3')}</Text>
            <Text size="sm">{t('content.worldline.contents.aws.cloudformation')}</Text>
            <Text size="sm">{t('content.worldline.contents.aws.cloudfront')}</Text>
            <Text size="sm">{t('content.worldline.contents.aws.dynamodb')}</Text>
            <Text size="sm">{t('content.worldline.contents.aws.rds')}</Text>
            <Text size="sm">{t('content.worldline.contents.aws.lambda')}</Text>
            <Text size="sm">{t('content.worldline.contents.aws.cognito')}</Text>
            <Text size="sm">{t('content.worldline.contents.aws.amplify')}</Text>
          </Grid.Col>
          <Grid.Col xs={6} md={4} xl={2}>
            <Title order={5} align="left">
              {t('content.worldline.contents.frontend.title')}
              {' '}
              <IconDeviceLaptop />
            </Title>
            <Text size="sm">{t('content.worldline.contents.frontend.angular')}</Text>
            <Text size="sm">{t('content.worldline.contents.frontend.react')}</Text>
          </Grid.Col>
          <Grid.Col xs={6} md={4} xl={2}>
            <Title order={5} align="left">
              {t('content.worldline.contents.native.title')}
              {' '}
              <IconDevices />
            </Title>
            <Text size="sm">{t('content.worldline.contents.native.android')}</Text>
          </Grid.Col>
          <Grid.Col xs={6} md={4} xl={3}>
            <Title order={5} align="left">
              {t('content.worldline.contents.other.title')}
              {' '}
              <IconSettings />
            </Title>
            <Text size="sm">{t('content.worldline.contents.other.git')}</Text>
            <Text size="sm">{t('content.worldline.contents.other.apirest')}</Text>
            <Text size="sm">{t('content.worldline.contents.other.graphql')}</Text>
            <Text size="sm">{t('content.worldline.contents.other.mysql')}</Text>
            <Text size="sm">{t('content.worldline.contents.other.postgres')}</Text>
            <Text size="sm">{t('content.worldline.contents.other.mongodb')}</Text>
            <Text size="sm">{t('content.worldline.contents.other.mqtt')}</Text>
            <Text size="sm">{t('content.worldline.contents.other.iot')}</Text>
            <Text size="sm">{t('content.worldline.contents.other.dialogflow')}</Text>
            <Text size="sm">{t('content.worldline.contents.other.scrum')}</Text>
          </Grid.Col>
        </Grid>
      </Collapse>
    </Box>
  );
}

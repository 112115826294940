export default class Constants {
  static GITHUB_LINK = 'https://github.com/jorge-tobarra?tab=repositories';

  static LINKEDIN_LINK = 'https://www.linkedin.com/in/jorge-tobarra-del-buey/';
}

export const LANGUAGES = [
  { name: 'Español', code: 'es' },
  { name: 'English', code: 'en' },
];

import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Title, Text, Container, Button, Overlay, createStyles,
} from '@mantine/core';
import { Link } from 'react-router-dom';
import Constants from '../config/constants';

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: 'relative',
    padding: 30,
    // backgroundImage: foto,
    backgroundImage: 'url(/images/foto-mirando-a-pantalla.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',

    '@media (max-width: 520px)': {
      padding: 20,
    },
  },

  inner: {
    position: 'relative',
    zIndex: 1,
  },

  title: {
    fontWeight: 800,
    fontSize: 40,
    letterSpacing: -1,
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    color: theme.white,
    marginBottom: theme.spacing.xs,
    textAlign: 'center',
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,

    '@media (max-width: 520px)': {
      fontSize: 28,
    },
  },

  highlight: {
    color: theme.colors[theme.primaryColor][4],
  },

  subtitle: {
    color: theme.colors.gray[0],
    paddingBlock: '1rem',
  },

  description: {
    color: theme.colors.gray[0],
    textAlign: 'center',

    '@media (max-width: 520px)': {
      fontSize: theme.fontSizes.md,
    },
  },

  controls: {
    marginTop: theme.spacing.xl * 1.5,
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,

    '@media (max-width: 520px)': {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },

  control: {
    height: 42,
    width: '20ch',
    borderRadius: '0.3rem',
    fontSize: theme.fontSizes.md,

    '@media (max-width: 520px)': {
      marginInline: theme.spacing.md,
    },
  },

  secondaryControl: {
    backgroundColor: 'rgba(255, 255, 255, .4)',
    marginLeft: theme.spacing.md,

    '@media (max-width: 520px)': {
      marginTop: theme.spacing.md,
    },

    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, .45) !important',
    },
  },
}));

export default function ProfileBanner() {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.wrapper}>
      <Overlay color="#000" opacity={0.40} zIndex={1} />

      <div className={classes.inner}>
        <Title className={classes.title}>
          Jorge
          {' '}
          <Text component="span" inherit className={classes.highlight}>
            Tobarra del Buey
          </Text>
        </Title>
        <Title align="center" order={2} className={classes.subtitle}>{t('subtitle')}</Title>

        <Container size={740}>
          <Text size="lg" className={classes.description}>
            <p>{t('description.1')}</p>
            <p>{t('description.2')}</p>
            <p>{t('description.3')}</p>
          </Text>
        </Container>

        <div className={classes.controls}>
          <Link to="/portfolio">
            <Button className={classes.control} variant="white" size="lg">
              Portfolio
            </Button>
          </Link>
          <a href={Constants.GITHUB_LINK} target="_blank" rel="noreferrer">
            <Button className={cx(classes.control, classes.secondaryControl)} size="lg">
              Github
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
}

/* eslint-disable no-empty-pattern */
import React from 'react';
import {
  Box, createStyles, Title, Text,
} from '@mantine/core';
import { useTranslation } from 'react-i18next';
// import WlLogo from '../../assets/wl-logo.png';

const useStyles = createStyles((theme) => ({
  generalBox: {
    marginBlock: theme.spacing.sm,
    padding: theme.spacing.sm,
  },
  detailsBox: {
    margin: theme.spacing.sm,
  },
  title: {
  },
  description: {
    paddingBottom: theme.spacing.sm,
  },
}));

interface AbilitiesEntriesProps {
  title: string,
  description: string,
}
export function AbilitiesEntry({ title, description }: AbilitiesEntriesProps) {
  const { classes } = useStyles();

  return (
    <>
      <Title className={classes.title} order={3}>{title}</Title>
      <Text className={classes.description} size="md">{description}</Text>
    </>
  );
}

interface AbilitiesProps {
}

export default function Abilities({}: AbilitiesProps) {
  const { classes } = useStyles();
  const { t } = useTranslation('translation', { keyPrefix: 'abilities.content' });

  return (
    <Box className={classes.generalBox}>
      {/* Si crece, mete 3 cols de grid */}
      <AbilitiesEntry
        title={t('car-licence.title')}
        description={t('car-licence.description')}
      />
    </Box>
  );
}

/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-undef */
/* eslint-disable no-empty-pattern */
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box, createStyles, Text, Title, Grid, Image,
} from '@mantine/core';

const useStyles = createStyles((theme) => ({
  generalBox: {
    paddingBlock: theme.spacing.sm,
  },
  title: {
    textAlign: 'center',
  },
  subtitle: {
  },
  date: {
    color: 'var(--accent-color-dark)',
    textAlign: 'center',
  },
  description: {
    paddingBottom: theme.spacing.sm,
    textAlign: 'center',
  },
  children: {
    marginBlock: theme.spacing.md,
  },
  logo: {
    maxWidth: 130,
    [theme.fn.smallerThan('sm')]: {
      display: 'inline-flex',
    },
  },
  logoBox: {
  },
}));

interface EducationEntryProps {
  title: string,
  level: string,
  place: string,
  startDate: string,
  endDate: string,
  logo?: any,
  extras?: { title: string, description: string }[],
  contents?: string[],
}

export default function EducationEntry({
  title, level, place, startDate, endDate, logo, extras, contents,
}: EducationEntryProps) {
  const { classes } = useStyles();
  const { t } = useTranslation('translation', { keyPrefix: 'education.content' });

  return (
    <Box className={classes.generalBox}>
      <Grid>
        {!!logo && (
        <Grid.Col xs={2} style={{ textAlign: 'center' }} className={classes.logoBox}>
          <Image src={logo} className={classes.logo} />
        </Grid.Col>
        )}
        <Grid.Col xs={logo ? 10 : 12}>
          <Title className={classes.title} order={2}>{title}</Title>
          <Title className={classes.title} order={3}>{level}</Title>
          <Text className={classes.date} transform="uppercase" weight={700}>
            {`${startDate} - ${endDate}`}
          </Text>
          <Text className={classes.description} size="md">{place}</Text>
        </Grid.Col>
        {extras && (
          <Grid.Col xs={contents ? 6 : 12}>
            <Title className={classes.subtitle} order={3}>{t('gei.extras.title')}</Title>
            <Box className={classes.children}>
              {extras.map((extra) => (
                <p key={extra.title}>
                  <strong>{extra.title}</strong>
                  {' - '}
                  {extra.description}
                </p>
              ))}
            </Box>
          </Grid.Col>
        )}
        {contents && (
          <Grid.Col xs={extras ? 6 : 12}>
            <Title className={classes.subtitle} order={3}>{t('gei.contents.title')}</Title>
            <Box className={classes.children}>
              {contents.map((content) => <p key={content}>{content}</p>)}
            </Box>
          </Grid.Col>
        )}
      </Grid>
    </Box>
  );
}

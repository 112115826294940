/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-undef */
/* eslint-disable no-empty-pattern */
import React from 'react';
import {
  Box, createStyles, Text, Title, Grid, Image,
} from '@mantine/core';
import { IconSquareRotated } from '@tabler/icons';

const useStyles = createStyles((theme) => ({
  generalBox: {
    paddingBlock: theme.spacing.sm,
  },
  title: {
    paddingBottom: theme.spacing.sm,
  },
  description: {
    paddingBottom: theme.spacing.sm,
  },
  children: {
    marginBlock: theme.spacing.md,
  },
}));

interface ExperienceCurricularProps {
  title: string,
  content: string[],
  // eslint-disable-next-line react/require-default-props
  logo?: any,
}

export default function ExperienceCurricularEntry({
  title, content, logo,
}: ExperienceCurricularProps) {
  const { classes } = useStyles();

  return (
    <Box className={classes.generalBox}>
      <Grid>
        {logo && (
        <Grid.Col xs={2}>
          <Image src={logo} width={130} />
        </Grid.Col>
        )}
        <Grid.Col xs={logo ? 10 : 12}>
          <Title className={classes.title} order={3}>{title}</Title>
          {content?.map((entry) => (
            <Text className={classes.description} size="lg" key={entry}>
              <IconSquareRotated size={13} style={{ color: 'var(--accent-color-dark)', marginRight: 5 }} />
              {entry}
            </Text>
          ))}
        </Grid.Col>
      </Grid>
    </Box>
  );
}

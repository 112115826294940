/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-undef */
/* eslint-disable no-empty-pattern */
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box, createStyles, Text, Title, Grid, Image, Space,
} from '@mantine/core';

const useStyles = createStyles((theme) => ({
  generalBox: {
    paddingBlock: theme.spacing.sm,
    [theme.fn.smallerThan('sm')]: {
      textAlign: 'center',
    },
  },
  title: {
  },
  date: {
    color: 'var(--accent-color-dark)',
  },
  description: {
    paddingBottom: theme.spacing.sm,
    paddingBlock: theme.spacing.sm,
  },
  horizontalLine: {
    border: 'solid 2px var(--accent-color-dark)',
    backgroundColor: 'var(--accent-color-dark)',
    width: '4px',
    height: '100%',
    marginLeft: '4rem',
    paddingBlock: theme.spacing.sm,
    [theme.fn.smallerThan('xs')]: {
      display: 'none',
    },
  },
  children: {
    marginBlock: theme.spacing.md,
  },
  centered: {
    textAlign: 'center',
  },
  righted: {
    textAlign: 'right',
    paddingBlock: theme.spacing.sm,
    [theme.fn.smallerThan('sm')]: {
      textAlign: 'center',
    },
  },
}));

interface LanguageEntryProps {
  title: string,
  level: string,
  certificate: string,
  date: string,
  flag: any,
  extraInfo?: string[],
  experience?: string[],
}

export default function LanguageEntry({
  title, level, certificate, date, flag, extraInfo, experience,
}: LanguageEntryProps) {
  const { classes } = useStyles();
  const { t } = useTranslation('translation', { keyPrefix: 'languages.content' });

  return (
    <Box className={classes.generalBox}>
      <Grid>
        <Grid.Col xs={2}>
          <Grid justify="center" align="center">
            <Image src={flag} style={{ maxWidth: 100, paddingBlock: 16 }} />
          </Grid>
        </Grid.Col>
        <Grid.Col xs={extraInfo ? 4 : 10}>
          {!certificate && <Space h="sm" />}
          <Title className={classes.title} order={2}>{title}</Title>
          <Title className={classes.title} order={3}>{level}</Title>
          <Text className={classes.date} transform="uppercase" weight={700}>
            {date}
          </Text>
          <Text className={classes.description} size="md">{certificate}</Text>
        </Grid.Col>
        {extraInfo && (
          <Grid.Col xs={6}>
            <Title className={classes.righted} order={3}>{t('english.extra-info.title')}</Title>
            <Box className={classes.children}>
              {extraInfo.map(
                (info) => <p className={classes.righted} key={`entry-col-${info}`}>{info}</p>,
              )}
            </Box>
          </Grid.Col>
        )}
        {experience && [
          <Grid.Col xs={2}><div className={classes.horizontalLine} /></Grid.Col>,
          <Grid.Col xs={10}>
            <Title className={classes.centered} order={3}>{t('english.experience.title')}</Title>
            <Box className={classes.children}>
              {experience.map(
                (exp) => <p className={classes.centered} key={`exp-${exp}`}>{exp}</p>,
              )}
            </Box>
          </Grid.Col>,
        ]}
      </Grid>
    </Box>
  );
}

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Navbar, ScrollArea, createStyles,
} from '@mantine/core';
import {
  IconNotes,
  IconCode,
  IconBrandGithub,
  IconBrandLinkedin,
} from '@tabler/icons';
import LinksGroup from './NavBarNested/LinkGroup';
import Constants from '../config/constants';

const sidebarLinks = [
  {
    label: 'CV',
    icon: IconNotes,
    initiallyOpened: true,
    mainRoute: '/',
    links: [
      { label: 'Experiencia', link: '/', hash: 'experience' }, // TODO: translate
      { label: 'Idiomas', link: '/', hash: 'languages' },
      { label: 'Educación', link: '/', hash: 'education' },
      { label: 'Cursos', link: '/', hash: 'courses' },
      { label: 'Habilidades', link: '/', hash: 'abilities' },
    ],
  },
  { label: 'Github', icon: IconBrandGithub, mainRoute: Constants.GITHUB_LINK },
  { label: 'LinkedIn', icon: IconBrandLinkedin, mainRoute: Constants.LINKEDIN_LINK },
  {
    label: 'Portfolio',
    icon: IconCode,
    mainRoute: '/portfolio',
    initiallyOpened: true,
    links: [
      { label: 'CV Online', link: '/portfolio' },
      { label: 'TBA', link: '/portfolio' },
    ],
  },
];

const useStyles = createStyles((theme) => ({
  navbar: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
    paddingBottom: 0,
    minWidth: '200px !important',
  },

  header: {
    padding: theme.spacing.md,
    paddingTop: 0,
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    borderBottom: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  links: {
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
  },

  linksInner: {
    // paddingTop: theme.spacing.xl,
    // paddingBottom: theme.spacing.xl,
  },

  footer: {
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
    borderTop: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
}));

interface NavbarNestedProps {
  hidden: boolean;
  toggleNavbarOpened: Function;
}

export default function NavbarNested({ hidden, toggleNavbarOpened }: NavbarNestedProps) {
  const { classes } = useStyles();
  const links = sidebarLinks.map((item) => (
    <LinksGroup {...item} key={item.label} toggleNavbarOpened={toggleNavbarOpened} />));

  return (
    <Navbar hidden={hidden} hiddenBreakpoint="md" height={800} width={{ sm: 300 }} p="md" className={classes.navbar}>
      <Navbar.Section grow className={classes.links} component={ScrollArea}>
        <div className={classes.linksInner}>{links}</div>
      </Navbar.Section>
    </Navbar>
  );
}

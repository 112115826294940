/* eslint-disable no-unused-vars */
import React from 'react';
import {
  createStyles, Anchor, Group, ActionIcon,
} from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { IconMail, IconBrandLinkedin, IconPhone } from '@tabler/icons';
import { ReactComponent as Logo } from '../logo.svg';
import Constants from '../config/constants';

const useStyles = createStyles((theme) => ({
  footer: {
    borderTop: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
  },

  inner: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${theme.spacing.md}px ${theme.spacing.md}px`,

    [theme.fn.smallerThan('sm')]: {
      flexDirection: 'column',
    },
  },

  links: {
    [theme.fn.smallerThan('sm')]: {
      marginTop: theme.spacing.lg,
      marginBottom: theme.spacing.sm,
    },
  },
}));

interface FooterResponsiveProps {
}

export default function FooterResponsive() {
  const { classes } = useStyles();
  const { t, i18n } = useTranslation();

  return (
    <div className={classes.footer}>
      <div className={classes.inner}>
        <Logo style={{ width: 48 }} />

        <Group className={classes.links}>{t('footer')}</Group>

        <Group spacing="xs" position="right" noWrap>
          <a href="mailto:tobarra.jorge@gmail.com">
            <ActionIcon size="lg" variant="default" radius="xl">
              <IconMail size="18" />
            </ActionIcon>
          </a>
          {/* <ActionIcon size="lg" variant="default" radius="xl">
            <IconPhone size="18" />
          </ActionIcon> */}
          <a href={Constants.LINKEDIN_LINK} target="_blank" rel="noreferrer">
            <ActionIcon size="lg" variant="default" radius="xl">
              <IconBrandLinkedin size="18" />
            </ActionIcon>
          </a>
        </Group>
      </div>
    </div>
  );
}

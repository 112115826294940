/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-undef */
/* eslint-disable no-empty-pattern */
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box, createStyles, Text, Title, Grid,
} from '@mantine/core';

const useStyles = createStyles((theme) => ({
  generalBox: {
    paddingBlock: theme.spacing.sm,
  },
  title: {
  },
  date: {
    color: 'var(--accent-color-dark)',
  },
  description: {
    paddingBottom: theme.spacing.sm,
  },
  children: {
    marginBlock: theme.spacing.md,
  },
}));

interface CoursesEntryProps {
  title: string,
  level: string,
  hours: string,
  startDate: string,
  endDate: string,
  place: string,
  logo?: any,
  contents?: string[],
}

export default function CoursesEntry({
  title, level, hours, startDate, endDate, place, logo, contents,
}: CoursesEntryProps) {
  const { classes } = useStyles();
  const { t } = useTranslation('translation', { keyPrefix: 'courses.content' });

  return (
    <Grid>
      {logo && (
        <Grid.Col xs={2} style={{ textAlign: 'center', alignSelf: 'center' }}>
          <span style={{ fontSize: 30 }}>{logo}</span>
        </Grid.Col>
      )}
      <Grid.Col xs={logo ? 10 : 12}>
        <Title className={classes.title} order={2}>{title}</Title>
        {level && <Title className={classes.title} order={3}>{level}</Title>}
        <Title className={classes.title} order={3}>{hours}</Title>
        <Text className={classes.date} transform="uppercase" weight={700}>
          {startDate}
          {endDate && ` - ${endDate}`}
        </Text>
        <Text className={classes.description} size="md">{place}</Text>
      </Grid.Col>
      {contents && (
      <Grid.Col xs={12}>
        <Title className={classes.title} order={3}>{t('ele.contents.title')}</Title>
        <Box className={classes.children}>
          {contents.map((exp) => <p key={exp}>{exp}</p>)}
        </Box>
      </Grid.Col>
      )}
    </Grid>
  );
}

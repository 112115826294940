/* eslint-disable no-unused-vars */
import React from 'react';
import {
  MantineProvider, MantineThemeOverride, AppShell, Paper,
} from '@mantine/core';
import { useBooleanToggle } from '@mantine/hooks';
// import logo from './logo.svg';
import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
} from 'react-router-dom';
import HeaderResponsive from './components/HeaderResponsive';
import FooterResponsive from './components/FooterResponsive';
import NavbarNested from './components/NavBarNested';
import Content from './Content';
import { LANGUAGES } from './config/constants';

const myTheme: MantineThemeOverride = {
  colorScheme: 'light',
  primaryColor: 'yellow',
  defaultRadius: 0,
};

function AppLayout() {
  const [navbarOpened, toggleNavbarOpened] = useBooleanToggle(false);

  return (
    <MantineProvider theme={myTheme} withGlobalStyles withNormalizeCSS>
      <AppShell
        padding={0}
        navbarOffsetBreakpoint="lg"
        navbar={(
          <NavbarNested hidden={!navbarOpened} toggleNavbarOpened={toggleNavbarOpened} />
        )}
        header={(
          <HeaderResponsive
            languages={LANGUAGES}
            navbarOpened={navbarOpened}
            toggleNavbarOpened={toggleNavbarOpened}
          />
        )}
        styles={(theme) => ({
          // eslint-disable-next-line max-len
          // main: { backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0] },
        })}
      >
        <div className="App">
          <Paper shadow="xs" p="md" className="App-paper center">
            <Outlet />
            <FooterResponsive />
          </Paper>
        </div>
      </AppShell>
    </MantineProvider>
  );
}

function App() {
  const router = createBrowserRouter([
    {
      element: <AppLayout />,
      children: [
        {
          path: '/:lang?/',
          element: <Content />,
        },
        {
          path: '/',
          element: <Content />,
        },
        {
          path: '/portfolio',
          element: <div style={{ marginBottom: '1rem', textAlign: 'center' }}>Portfolio page in progress</div>,
        },
      ],
    },
  ]);

  return (
    <RouterProvider router={router} />
  );
}

export default App;

/* eslint-disable no-empty-pattern */
import React from 'react';
import { Box, createStyles } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import UKFlag from '../../assets/languages/FlagBritishCV.png';
import FranceFlag from '../../assets/languages/FlagFrenchCV.png';
import GermanyFlag from '../../assets/languages/FlagGermanCV.png';
import SwedenFlag from '../../assets/languages/FlagSwedishCV.png';
import LanguageEntry from './Entry';

const useStyles = createStyles((theme) => ({
  generalBox: {
    marginBlock: theme.spacing.sm,
    padding: theme.spacing.sm,
  },
  detailsBox: {
    margin: theme.spacing.sm,
  },
}));

interface LanguagesProps {
}

export default function Languages({}: LanguagesProps) {
  const { classes } = useStyles();
  const { t } = useTranslation('translation', { keyPrefix: 'languages.content' });

  return (
    <Box className={classes.generalBox}>
      <LanguageEntry
        title={t('english.language')}
        level={t('english.level')}
        date={t('english.date')}
        certificate={t('english.certificate')}
        extraInfo={[
          t('english.extra-info.paragraphs.1'),
          t('english.extra-info.paragraphs.2'),
        ]}
        experience={[
          t('english.experience.erasmus'),
          t('english.experience.degree'),
          t('english.experience.master'),
          t('english.experience.internships'),
          t('english.experience.tfg'),
          t('english.experience.tfm'),
          t('english.experience.dam'),
        ]}
        flag={UKFlag}
      />
      <div className="separator" />
      <LanguageEntry
        title={t('swedish.language')}
        level={t('swedish.level')}
        date={t('swedish.date')}
        certificate={t('swedish.certificate')}
        flag={SwedenFlag}
      />
      <div className="separator" />
      <LanguageEntry
        title={t('german.language')}
        level={t('german.level')}
        date={t('german.date')}
        certificate={t('german.certificate')}
        extraInfo={[
          t('german.extra-info.paragraphs.1'),
          t('german.extra-info.paragraphs.2'),
        ]}
        flag={GermanyFlag}
      />
      <div className="separator" />
      <LanguageEntry
        title={t('french.language')}
        level={t('french.level')}
        date={t('french.date')}
        certificate=""
        extraInfo={[t('french.extra-info.paragraphs.1')]}
        flag={FranceFlag}
      />
    </Box>
  );
}

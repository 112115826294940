/* eslint-disable no-empty-pattern */
import React from 'react';
import { Box, createStyles } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import JavaLogo from '../../assets/education/JavaLogo.png';
import UCMLogo from '../../assets/education/UCMLogo.png';
import UMLogo from '../../assets/education/UMLogo.jpg';
import EducationEntry from './Entry';

const useStyles = createStyles((theme) => ({
  generalBox: {
    marginBlock: theme.spacing.sm,
    padding: theme.spacing.sm,
  },
  detailsBox: {
    margin: theme.spacing.sm,
  },
}));

interface EducationProps {
}

export default function Education({}: EducationProps) {
  const { classes } = useStyles();
  const { t } = useTranslation('translation', { keyPrefix: 'education.content' });

  return (
    <Box className={classes.generalBox}>
      <EducationEntry
        title={t('dam.title')}
        level={t('dam.level')}
        startDate={t('dam.startDate')}
        endDate={t('dam.endDate')}
        place={t('dam.place')}
        logo={JavaLogo}
        contents={[
          t('dam.contents.java'),
          t('dam.contents.android'),
          t('dam.contents.tools'),
          t('dam.contents.os'),
          t('dam.contents.markup'),
        ]}
      />
      <EducationEntry
        title={t('mfp.title')}
        level={t('mfp.level')}
        startDate={t('mfp.startDate')}
        endDate={t('mfp.endDate')}
        place={t('mfp.place')}
        logo={UCMLogo}
        extras={[
          { title: t('mfp.extras.tfm.title'), description: t('mfp.extras.tfm.description') },
        ]}
        contents={[
          t('mfp.contents.didactics'),
          t('mfp.contents.linguistics'),
          t('mfp.contents.legislation'),
        ]}
      />
      <EducationEntry
        title={t('gei.title')}
        level={t('gei.level')}
        startDate={t('gei.startDate')}
        endDate={t('gei.endDate')}
        place={t('gei.place')}
        logo={UMLogo}
        extras={[
          { title: t('gei.extras.erasmus.title'), description: t('gei.extras.erasmus.description') },
          { title: t('gei.extras.tfg.title'), description: t('gei.extras.tfg.description') },
        ]}
        contents={[
          t('gei.contents.english'),
          t('gei.contents.culture'),
          t('gei.contents.literature'),
          t('gei.contents.linguistics'),
          t('gei.contents.translation'),
          t('gei.contents.history'),
          t('gei.contents.esl'),
        ]}
      />
      <EducationEntry
        title={t('matematicas.title')}
        level={t('matematicas.level')}
        startDate={t('matematicas.startDate')}
        endDate={t('matematicas.endDate')}
        place={t('matematicas.place')}
        logo={UMLogo}
        contents={[
          t('matematicas.contents.programming'),
          t('matematicas.contents.physics'),
        ]}
      />
    </Box>
  );
}

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty-pattern */
import React from 'react';
import {
  Box, createStyles, Text, Title, Grid, Collapse,
} from '@mantine/core';
import { useTranslation } from 'react-i18next';
import {
  IconServer2, IconDeviceLaptop, IconDevices, IconSettings, IconBrandAmazon,
  IconChevronDown, IconChevronUp,
} from '@tabler/icons';

const useStyles = createStyles((theme) => ({
  generalBox: {
    marginBlock: theme.spacing.sm,
  },
}));

interface ExperienceProfessionalSabadellProps {
  [x: string]: any,
}

export default function ExperienceProfessionalSabadell(
  { ...props }: ExperienceProfessionalSabadellProps,
) {
  const { classes, theme } = useStyles();
  const { t } = useTranslation('translation', { keyPrefix: 'experience.content.professional' });
  const [opened, setOpened] = React.useState(false);

  return (
    <Box {...props}>
      <Title order={4} align="center" onClick={() => setOpened(!opened)} sx={{ padding: 0, marginBottom: theme.spacing.sm }}>
        {t('content.sabadell-teller.contents.title')}
        {opened ? (
          <IconChevronUp style={{ width: 15, height: 15, marginInline: 5 }} />)
          : <IconChevronDown style={{ width: 15, height: 15, marginInline: 5 }} />}
      </Title>
      <Collapse in={opened} transitionDuration={500} transitionTimingFunction="linear">
        <Grid justify="space-between" className="border-gradient">
          <Grid.Col xl={1} md={4} xs={6}>
            <Text size="sm">{t('content.sabadell-teller.contents.deposits')}</Text>
          </Grid.Col>
          <Grid.Col xl={1} md={4} xs={6}>
            <Text size="sm">{t('content.sabadell-teller.contents.withdraws')}</Text>
          </Grid.Col>
          <Grid.Col xl={1} md={4} xs={6}>
            <Text size="sm">{t('content.sabadell-teller.contents.transfers')}</Text>
          </Grid.Col>
          <Grid.Col xl={1} md={4} xs={6}>
            <Text size="sm">{t('content.sabadell-teller.contents.checks')}</Text>
          </Grid.Col>
          <Grid.Col xl={2} md={4} xs={6}>
            <Text size="sm">{t('content.sabadell-teller.contents.accounts')}</Text>
          </Grid.Col>
          <Grid.Col xl={2} md={4} xs={6}>
            <Text size="sm">{t('content.sabadell-teller.contents.atm')}</Text>
          </Grid.Col>
        </Grid>
      </Collapse>
    </Box>
  );
}

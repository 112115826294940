/* eslint-disable no-empty-pattern */
import React from 'react';
import {
  Box, createStyles, Title, Text,
} from '@mantine/core';
import { useTranslation } from 'react-i18next';
// import WlLogo from '../../assets/wl-logo.png';
import { IconSquareRotated } from '@tabler/icons';

const useStyles = createStyles((theme) => ({
  generalBox: {
    margin: theme.spacing.sm,
    padding: theme.spacing.sm,
  },
  detailsBox: {
    margin: theme.spacing.sm,
  },
  title: {
    paddingBottom: '1.5rem',
  },
  text: {
    paddingBottom: theme.spacing.sm,
    textAlign: 'justify',
  },
}));

interface AptitudesProps {
}

export default function Aptitudes({}: AptitudesProps) {
  const { classes } = useStyles();
  const { t } = useTranslation('translation', { keyPrefix: 'objectives' });

  return (
    <Box className={classes.generalBox}>
      <Title align="center" className={classes.title}>{t('title')}</Title>
      <Text className={classes.text}>
        <IconSquareRotated size={13} style={{ color: 'var(--accent-color-dark)', marginRight: 5 }} />
        {t('content.paragraphs.1')}
      </Text>
      <Text className={classes.text}>
        <IconSquareRotated size={13} style={{ color: 'var(--accent-color-dark)', marginRight: 5 }} />
        {t('content.paragraphs.2')}
      </Text>
      <Text className={classes.text}>
        <IconSquareRotated size={13} style={{ color: 'var(--accent-color-dark)', marginRight: 5 }} />
        {t('content.paragraphs.3')}
      </Text>
    </Box>
  );
}
